<template>
  <div class="m-0 px-3 pt-4 pb-0">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Baritastic Programs</h1>
      <div class="d-flex justify-content-end">
        <base-button :disabled="$store.state.modals.downloadingReport"
                     size="md"
                     class="mr-2 title-btn-padding white-outline-button button-font"
                     @click="exportBaritasticPrograms" type="secondary"
        >
          <img alt="Image placeholder" class="mt--1" src="/img/svg/export.svg">Export
        </base-button>
        <router-link
          class="ml-2 "
          :to="{ name: 'admin.baritastic_program.store'}">
          <el-button class="btn-secondary-action title-btn-padding button-font button-hover-effect">Add New</el-button>
        </router-link>
      </div>
    </div>
    <div v-loading="loaders.programs" class="mt-4 cardStyle">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap my-3">
        <div class="d-flex" v-if="!selectedRows.length">
          <base-input class="d-inline-block width-285" label="Search" placeholder="Search by Email, Code or Name..."
                      v-model="request.search" type="search" @change="filterChange" @input="filterChange"/>
          <base-input label="Status" class="ml-3">
            <p @click="clearStatusAndGetPrograms" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="w-100"
              label="Status"
              placeholder=""
              v-model="request.status"
              @change="filterChange"
            >
              <el-option
                v-for="option in dropdowns.status"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div v-else id="select-option-container" class="mt-4 ml-4">
          <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                       @click="handleActivate"
                       :loading="loaders.checkboxButton.Activate"
          >Activate
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                       @click="handleDeactivate"
                       :loading="loaders.checkboxButton.DeActive"
          >Deactivate
          </base-button>
        </div>
      </div>
      <el-table
        row-key="id"
        class="programs-table"
        header-row-class-name="thead-light"
        width="100%"
        :data="response.programs"
        @selection-change="sectionListMultipleSelect"
      >
        <el-table-column prop="selected" type="selection" width="50">
        </el-table-column>
        <el-table-column label="NAME" width="300">
          <template slot-scope="scope">
            <div class="text-break">{{ scope.row.first_name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="PROGRAM CODE" width="150">
          <template slot-scope="scope">
            <div class="text-break  ">{{ scope.row.programmer_code }}</div>
          </template>
        </el-table-column>
        <el-table-column label="USER NAME" width="150">
          <template slot-scope="scope">
            <div class="text-break">{{ scope.row.username }}</div>
          </template>
        </el-table-column>
        <el-table-column label="EMAIL" width="250">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.email }}</div>
          </template>
        </el-table-column>
        <el-table-column label="SHOP URL" width="400">
          <template slot-scope="scope">
            <a class="trim-text-overflow" target="_blank" :href="scope.row.shop.url" v-if="scope.row.shop && scope.row.shop.url">{{ scope.row.shop.url  }}</a>
            <div v-else>N/A</div>
          </template>
        </el-table-column>
        <el-table-column label="TIER" width="80">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.tier }}</div>
          </template>
        </el-table-column>
        <el-table-column label="CREATED ON" width="120">
          <template slot-scope="scope">
            <div class="trim-text-overflow">{{ scope.row.created | getCreatedAt }}</div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" width="100">
          <template slot-scope="scope">
              <span :class="['status',getStatusClass(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatusClass(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" width="180">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip content="View Program" placement="top">
              <router-link
                :to="{name: 'admin.baritastic_program.show', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/eye.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip content="Edit Program" placement="top">
              <router-link
                :to="{name: 'admin.baritastic_program.edit', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" alt="">
              </router-link>
            </el-tooltip>
            <el-tooltip content="Change Password" placement="top">
              <router-link
                :to="{name: 'admin.baritastic_program.change_password', params: {id: row.id}}">
                <img class="pointer mr-2" src="/img/svg/lock.svg" alt="">
              </router-link>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <custom-pagination
            class="pagination-no-border float-right"
            v-model="currentPage"
            :per-page="perPage"
            :total="totalPages"
            @input="changePage"
          />
        </div>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeProgram"
        @onRemoveMultiple="removeMultiplePrograms"
      />
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui"
import CustomPagination from "@/views/Components/Pagination/CustomPagination"
import BackButton from "@/components/Router/BackButton";
import moment from "moment";
import fileDownload from "@/mixins/fileDownload";

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")
export default {
  name: "BaritasticPrograms",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    CustomPagination,
    BackButton
  },
  data() {
    return {
      loaders: {
        programs: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      btnDraggable: 'btnDraggable',
      request: {
        search: '',
        status: ''
      },
      dropdowns: {
        status: [
          {value: '', label: 'All Baritastic Programs'},
          {value: 1, label: 'Active'},
          {value: 0, label: 'Inactive',},
        ],
      },
      response: {
        programs: [],
      },
      currentPage: 1,
      totalPages: 1,
      perPage: 10
    }
  },
  mixins: [
    fileDownload
  ],
  computed: {
    /**
     * Extract the ids from selected rows from table
     * @returns {*[]}
     */
    extractSelectedIds() {
      let selectedIds = []
      for (const selectedRow of this.selectedRows) {
        selectedIds.push(selectedRow.id)
      }
      return selectedIds
    },
  },
  filters: {
    /**
     * Reformat created date
     * @param date
     * @returns {string}
     */
    getCreatedAt: function (date) {
      return moment.utc(date).local().format('DD-MMM-YYYY')
    }
  },
  mounted() {
    this.getProgramsListing()
  },
  methods: {
    filterChange(){
      this.currentPage = 1;
      this.getProgramsListing()
    },
    /**
     * Change page
     * @param item
     */
    changePage(item) {
      this.currentPage = item
      this.getProgramsListing()
    },

    /**
     * Clear status and get programs listing
     */
    clearStatusAndGetPrograms() {
      this.currentPage = 1;
      this.request.status = ''
      this.getProgramsListing()
    },

    /**
     * Get Baritastic Programs Listing
     */
    getProgramsListing() {
      let vm = this
      vm.loaders.programs = true
      const payload = {
        page: vm.currentPage,
        search: vm.request.search,
        status: vm.request.status
      }
      vm.$store.dispatch('BaritasticProgramModule/_getBaritasticPrograms', payload)
        .then(response => {
          vm.response.programs = response.data.data.data
          vm.currentPage = response.data.data.current_page
          vm.totalPages = response.data.data.total
          vm.perPage = response.data.data.per_page;
        })
        .catch(error => {
          if (!axios.isCancel(error)) {
              const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
              vm.$notify.error({
                title: 'Baritastic Programs',
                message: message
              })
          }
        })
        .finally(() => {
          vm.loaders.programs = false
        })
    },

    /**
     * Export Baritastic Programs
     */
    exportBaritasticPrograms() {
      let vm = this
      vm.$store.commit('toggleDownloadReport', true)
      const payload = {
        ...vm.request
      }
      vm.$store.dispatch('BaritasticProgramModule/_exportBaritasticPrograms', payload)
        .then(response => {
          vm.generateExportFileDownload(response, 'Baritastic_Programs')
        })
        .catch(error => {
          console.log(error)
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Baritastic Programs Export',
            message: message
          })
        })
        .finally(() => {
          vm.$store.commit('toggleDownloadReport', false)
        })
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'Inactive-Status' : 'Active-Status'
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle the active button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle the deactivate button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Handle delete button event
     * @param index
     * @param row
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Program.'
      this.removeId = row.id
    },

    /**
     * Prompt the Remove modal for multiple items
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these Programs.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handle event for selecting multiple rows from table
     * @param selectedRows
     */
    sectionListMultipleSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Handle remove multiple items event
     */
    removeMultiplePrograms() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Handle remove single item event
     * @param id
     */
    removeProgram(id) {
      this.deleteRequest(id)
    },

    /**
     * Delete Program(s)
     * @param data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.programs = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]}
      vm.$store.dispatch('BaritasticProgramModule/_deleteBaritasticProgram', payload)
        .then(response => {
          vm.removeId = []
          vm.getProgramsListing()
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: 'Program Deleted Successfully.'
          })
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Program',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton.Delete = false
          vm.loaders.programs = false
        })
    },

    /**
     * Update the status for selected row(s) from table
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.programs = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }

      vm.$store.dispatch('BaritasticProgramModule/_updateStatusProgram', payload)
        .then(response => {
          vm.removeId = []
          vm.$notify.success({
            title: 'Success',
            message: 'Status Updated Successfully.'
          })
          vm.getProgramsListing()
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Program Status',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.programs = false
        })
    },
  }
}
</script>

<style scoped>

</style>
